<template>
  <div>
    <b-list-group v-if="artefacts.length > 0">
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'artefacts' + index"
      >
        <b-row>
          <b-col>
            {{ artefact(item.id) }}
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'pages.artefacts.delete'"
              @click="removeArtefact(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <multiselect
      :placeholder="$t('pages.artefacts.select')"
      :options="artefacts.map(i => i.id)"
      :custom-label="k => artefacts.find(x => x.id == k).artefact"
      :show-labels="false"
      @select="addArtefact"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ArtefactsSelector",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      artefacts: []
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    addArtefact(element) {
      this.content.push({ id: element });
      this.$emit("change");
    },
    removeArtefact(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    artefact(id) {
      return this.artefacts.find(element => element.id == id).artefact;
    }
  },
  apollo: {
    artefacts() {
      return {
        query: gql`
          query artefacts {
            artefacts {
              id
              artefact
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          var _this = this;

          this.artefacts = result.data.artefacts.sort((a, b) =>
            a.artefact.localeCompare(b.artefact, "sv")
          );
        }
      };
    }
  }
};
</script>
